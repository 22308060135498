import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { theOemManager } from "../globals";

/**
 * Function to replace Application Name in localization string with OEM strings
 *
 * @param resources - a string
 * @returns resources, modified
 */
function replaceResourcePlaceholder(resources: string): string {
	// let's parse the strings. If the function returns undef
	resources = theOemManager.parseOEMStrings(resources) ?? resources;

	// now updating version information
	// eslint-disable-next-line prefer-regex-literals
	resources = resources.replace(new RegExp("{{APP_BUILD_VERSION}}", "g"), "v192");

	return resources;
}

// eslint-disable-next-line import/no-named-as-default-member
void i18n
	.use(Backend)
	.use(initReactI18next)
	.init({
		fallbackLng: {
			de: ["de-DE", "en-US"],
			default: ["en-US"]
		},
		load: "currentOnly",
		debug: false,
		interpolation: {
			escapeValue: false // not needed for react as it escapes by default
		},
		backend: {
			// for all available options read the backend's repository readme file
			loadPath: "./assets/locales/localization_{{lng}}.json",
			parse: (data: string): JSON => {
				data = replaceResourcePlaceholder(data);
				return JSON.parse(data) as JSON;
			}
		}
	});

export default i18n;
